<!--
 * @Author: wjc
 * @Date: 2023-11-01 15:53:21
 * @LastEditors: wjc
 * @LastEditTime: 2023-12-08 15:43:42
 * @Description: 
-->
<template>
  <Headers></Headers>
  <Menu :open="isScreenOpen" @toggle-screen="toggleScreen"></Menu>
  <MenuScreen :open="isScreenOpen"></MenuScreen>
  <main>
    <slot></slot>
  </main>
  <el-backtop :visibility-height="400" :right="20" :bottom="100">
    <span class="i-custom-fhdb"></span>
  </el-backtop>
  <Footer></Footer>
</template>

<script lang="ts" setup>
  import Headers from './components/headers.vue'
  import Menu from './components/menu.vue'
  import MenuScreen from './components/menu-screen/index.vue'
  import Footer from './components/footer.vue'

  defineOptions({
    name: 'LayoutDefault',
  })

  const { isScreenOpen, closeScreen, toggleScreen } = useNav()

  provide('close-screen', closeScreen)
</script>
